import * as Vue from 'vue'
import App from './App.vue'
import store from './store';
import WidgetApp from './WidgetApp.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import mitt from 'mitt';
import VueUniversalModal from 'vue-universal-modal';
import { VueReCaptcha } from 'vue-recaptcha-v3';
const emitter = mitt();

import 'vue-universal-modal/dist/index.css';

var element =  document.getElementById('app');
if (typeof(element) != 'undefined' && element != null)
{
  const app = Vue.createApp(App)
  app.config.globalProperties.emitter = emitter;
  app.use(store)
  app.use(VueAxios, axios)
  app.use(VueUniversalModal, {
    teleportTarget: '#modals',
  });
  app.use(VueReCaptcha, { siteKey: '6LfSlKYkAAAAANq3-iEFpfN2owPm-66LyEO8S7L9' })

  app.mount('#app')
  app.mixin({
    data: function() {
      return {
        get billing_frequency() {
          return "weekly";
        },
        get min_plan_price() {
          return 0;
        }
      }
    }
  })
  app.directive('click-outside', {
      beforeMount(el, binding) {
        el.clickOutsideEvent = function(event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event, el);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    });
}

var element2 =  document.getElementById('widget');
if (typeof(element2) != 'undefined' && element2 != null)
{
  const app2 = Vue.createApp(WidgetApp)
  app2.use(store)
  app2.use(VueAxios, axios)
  app2.mount('#widget')
  app2.mixin({
    data: function() {
      return {
        get billing_frequency() {
          return "weekly";
        },
        get min_plan_price() {
          return 0;
        }
      }
    }
  })
}