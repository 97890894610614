<template>
  <div class="filter-wrapper--scrollable">
    <div class="filter-wrapper--block" v-if="search_options && search_options.make && location_options">
      <div class="filter-wrapper--block--header">Location</div>
      <template v-for="locationObj in sortedLocationOptions" :key="locationObj.key">
        <CheckBoxField
          v-if="!['101', '111', '124', '325', '406', '407', '408', '409', '410', '411'].includes(locationObj.key)"
          fieldName="location"
          :visible="true"
          :fieldId="locationObj.key"
          v-model:contents="intermediate_filter.location"
          :label="locationObj.location"
        />
      </template>
    </div>

    <div class="filter-wrapper--block" v-if="search_options && search_options.make">
      <div class="filter-wrapper--block--header">Make</div>
      <CheckBoxField
        v-for="(make, index) in search_options.make"
        :visible="index < 5 || search_options_visible.make"
        :key="make"
        :fieldId="make"
        v-model:contents="intermediate_filter.make"
        :label="make"
      />
      <div
        v-if="!search_options_visible.make && search_options.make.length > 5"
        class="filter-wrapper--block--showall"
        @click="showAllFor('make')"
      >
        Show all {{ search_options.make.length }}
      </div>
    </div>

    <div class="filter-wrapper--block" v-if="search_options && search_options.year">
      <div class="filter-wrapper--block--header">Year</div>
      <RangeSliderField 
        name="year"
        :min="search_options.year[0]"
        :max="search_options.year[1]"
        v-model:contents="intermediate_filter.year"
      />
    </div>

    <div class="filter-wrapper--block" v-if="false && search_options && search_options.odometer">
      <div class="filter-wrapper--block--header">Odometer, km</div>
      <RangeSliderField 
        name="odometer"
        :min="search_options.odometer[0]"
        :max="search_options.odometer[1]"
        :round="true"
        v-model:contents="intermediate_filter.odometer"
      />
    </div>

    <div class="filter-wrapper--block" v-if="search_options && search_options.body_style">
      <div class="filter-wrapper--block--header">Body style</div>
      <CheckBoxField
        v-for="(body_style, index) in search_options.body_style"
        :visible="index < 5 || search_options_visible.body_style"
        :key="body_style"
        :fieldId="body_style"
        v-model:contents="intermediate_filter.body_style"
        :label="body_style"
      />
      <div
        v-if="
          !search_options_visible.body_style &&
          search_options.body_style.length > 5
        "
        class="filter-wrapper--block--showall"
        @click="showAllFor('body_style')"
      >
        Show all {{ search_options.body_style.length }}
      </div>
    </div>
    <div class="filter-wrapper--block" v-if="search_options && search_options.transmission">
      <div class="filter-wrapper--block--header">Transmission</div>
      <CheckBoxField
        v-for="(transmission, index) in search_options.transmission"
        :visible="index < 5 || search_options_visible.transmission"
        :key="transmission"
        :fieldId="transmission"
        v-model:contents="intermediate_filter.transmission"
        :label="transmission"
      />
      <div
        v-if="
          !search_options_visible.transmission &&
          search_options.transmission.length > 5
        "
        class="filter-wrapper--block--showall"
        @click="showAllFor('transmission')"
      >
        Show all {{ search_options.transmission.length }}
      </div>
    </div>
    <div class="filter-wrapper--block" v-if="search_options && search_options.fuel_type">
      <div class="filter-wrapper--block--header">Fuel type</div>
      <CheckBoxField
        v-for="(fuel_type, index) in search_options.fuel_type"
        :visible="index < 5 || search_options_visible.fuel_type"
        :key="fuel_type"
        :fieldId="fuel_type"
        v-model:contents="intermediate_filter.fuel_type"
        :label="fuel_type"
      />
      <div
        v-if="
          !search_options_visible.fuel_type && search_options.fuel_type.length > 5
        "
        class="filter-wrapper--block--showall"
        @click="showAllFor('fuel_type')"
      >
        Show all {{ search_options.fuel_type.length }}
      </div>
    </div>
    <div class="filter-wrapper--block" v-if="search_options && search_options.seats">
      <div class="filter-wrapper--block--header">Seats</div>
      <CheckBoxField
        v-for="(seats, index) in search_options.seats"
        :visible="index < 5 || search_options_visible.seats"
        :key="seats"
        :fieldId="seats"
        v-model:contents="intermediate_filter.seats"
        :label="seats"
      />
      <div
        v-if="!search_options_visible.seats && search_options.seats.length > 5"
        class="filter-wrapper--block--showall"
        @click="showAllFor('seats')"
      >
        Show all {{ search_options.seats.length }}
      </div>
    </div>
  </div>
</template>
<script>
import CheckBoxField from "./elements/CheckBoxField.vue";
import RangeSliderField from "./elements/RangeSliderField.vue";

export default {
  name: 'FilterBlock',
  props: {
    location_options: {
      type: Object,
    },
    search_options: {
      type: Object,
    },
    filter: {
      type: Object,
    },
  },
  components: {
    CheckBoxField,
    RangeSliderField,
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchQuery = urlParams.get('q');

    // Reset the intermediate_filter to its default state
    this.intermediate_filter = { page: 1, sort: 'lowest_price' };

    if (searchQuery) {
      this.intermediate_filter.string = searchQuery;
    } else {
      // Load the intermediate_filter from the filter prop or localStorage
      const storedFilter = JSON.parse(localStorage.getItem('filter')) || this.filter;
      this.intermediate_filter = JSON.parse(JSON.stringify(storedFilter));
    }

    const location = urlParams.get('location');
    if (location) {
      this.intermediate_filter.location = [location];
    }
  },
  data() {
    return {
      search_options_visible: {},
      intermediate_filter: {}
    };
  },
  computed: {
    sortedLocationOptions() {
      const locationArray = Object.entries(this.location_options).map(([key, location]) => ({ key, location }));
      
      locationArray.sort((a, b) => {
        if (a.location < b.location) return -1;
        if (a.location > b.location) return 1;
        return 0;
      });
      
      return locationArray;
    },
  },
  watch: {
    intermediate_filter: {
      handler(newFilter) {
        this.$emit('update:filter', newFilter);
      },
      deep: true
    }
  },
  methods: {
    showAllFor(type) {
      this.search_options_visible[type] = true
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-wrapper {
  &--scrollable {
    @media (max-width: 900px) {   
      overflow: scroll;
      padding: 0 20px;
    }
  }
  &--block {
    margin-top: 24px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: first baseline;

    &--header{ 
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #1A1B1B;
      margin-bottom: 5px;
    }

    &--showall {
      margin-top: 5px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #004E98;
      cursor: pointer;
    }
  }
}
</style>