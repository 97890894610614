<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" rel="stylesheet"> 

  <template v-if="featured_cars">
    <div class="heading-2" >Featured cars</div>
    <div class="car-wrapper">
      <BigCarBox v-for="f_car in featured_cars" :car="f_car" :key="f_car.id" :location_options="location_options" />
    </div>  
    <a href="/cars" class="button_browse button_w212" v-if="location_id == 0">Browse cars</a>
    <a :href="'/cars?location=' + location_id" class="button_browse button_w212" v-else>Browse cars</a>
  </template>
</template>
<script>
import BigCarBox from './components/BigCarBox.vue';

export default {
  name: 'WidgetApp',
  components: {
    BigCarBox
  },
  data() {
    return {
      host: "https://api.ecarsubscription.com.au/",
      featured_cars: [],
      location_id: 0,
      location_options: {
        101: 'Main',
        110: 'Adelaide',
        406: 'Adelaide',
        111: 'Brisbane Airport',
        408: 'Brisbane Airport',
        112: 'Brisbane',
        113: 'Cairns',
        407: 'Cairns',
        123: 'Melbourne',
        124: 'Melbourne Airport',
        409: 'Melbourne Airport',
        209: 'Newcastle',
        410: 'Newcastle',
        127: 'Gold Coast',
        125: 'Launceston',
        126: 'Hobart',
        120: 'Sunshine Coast',
        411: 'Sunshine Coast',
        128: 'Sydney',
        325: 'Sydney',
      },
    };
  },
  mounted() {
    this.$store.dispatch('fetchVehicleStateLabels');
    var element = document.querySelector('.location-id-override');
    var dataAttribute = element.innerHTML;
    this.getFeaturedCars(dataAttribute);
    this.location_id = parseInt(dataAttribute);
  },
  methods: {

    getFeaturedCars(location) {
      let api = `${this.host}/api/search.json`
      let query = {}
      if (parseInt(location) == 0) {
        query = {
          page: 1,
          featured: "true",
          sort: 'lowest_price',
        };
      } else {
        query = {
          page: 1,
          location: [location],
          featured: "true",
          only_location: "true",
          sort: 'lowest_price',
        };
      }
      let queryString = Object.keys(query).map(key => {
          if (query[key].constructor === Array) {
              var theArrSerialized = ''
              for (let singleArrIndex of query[key]) {
                  theArrSerialized = theArrSerialized + key + '[]=' + singleArrIndex + '&'
              }
              return theArrSerialized
          }
          else {
              return key + '=' + query[key] + '&'
          }
      }).join('');

      this.axios.get(api + '?' + queryString).then((response) => {
        this.featured_cars = response.data.cars;
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.car-wrapper {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 20px;
  margin-top: 48px;
  
  .car-card {
    //width: 272px;
    margin: 0px 12px 24px;

    @media (max-width: 600px) {
      width: calc(100% - 24px);
    }
  }
}
.button_browse {
  width: 212px;
  display: flex;
  text-align: center;
  margin: 0 auto 48px;
}
.heading-2 {
  text-align: center;
}
</style>
