<template>
  <div class="search-commit">
    <div>{{total}} results</div>
    <div>
      <button class="button" @click="show">Show</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    filter: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      total: 0,
    };
  },
  mounted() {
    this.emitter.on("countSuccess", count => {
      this.total = count;
    });
  },
  methods: {
    show() {
      this.emitter.emit("commitSearch");
    }
  }
};
</script>
<style lang="scss" scoped>
  .search-commit {
    background: rgba(26, 27, 27, 0.9);
    padding: 16px 25px 22px 24px; 
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border-radius: 8px;
    position: relative;
    left: 33px;
    margin: -100px 0;
    flex-shrink: 0;
  }
  .search-commit:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(51, 59, 161, 0);
    border-right-color: rgba(26, 27, 27, 0.9);
    border-width: 24px;
    margin-top: -24px;
  }
  .search-commit .button {
    width: 80px;
    height: 36px;
    background: #52BDC4;
    border-radius: 50px;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.25s;
  }
  .search-commit .button:hover {
    opacity: 0.9;
  }
</style>