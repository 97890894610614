<template>
    <div class="input" :class="{input_small: small, disabled: disabled}">
        <label v-if="label" class="input__label" :for="uuid" v-html="label"></label>
        <div class="input__box" :style="{width: (w ? w : 50  ) + '%'}"
             :class="{input__box_open: open}">
            <div class="input__input" :class="{input__input_focus: 'open'}" @click="$refs.search.focus()">
                <span @click="onSearchFocus"
                      class="input__selected" v-show="!open" v-html="visibleLabel"></span>
                <input v-if="enabled"
                       class="input__search" :id="uuid" type="text" ref="search"
                       :class="{ 'input__search--readonly': onlySelect ? true : false }"
                       v-bind:placeholder="open ? visibleLabel : ''"
                       autocomplete="off"
                       tabindex="-1"
                       :readonly="onlySelect ? true : false"
                       :inputmode="searchInputMode ? searchInputMode : 'text'"
                       @focus="onSearchFocus"
                       @blur="onSearchBlur"
                       v-model="search">

                <i v-if="loading" class="fa fa-cog fa-spin" role="presentation"></i>
                <svg v-else-if="open" v-on:click.stop="$refs.search.blur()" fill="none" style="transform: rotate(-180deg);" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m7.41 8.59-1.41 1.41 6 6 6-6-1.41-1.41-4.59 4.58z" fill="#747676"/></svg>
                <svg v-else fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m7.41 8.59-1.41 1.41 6 6 6-6-1.41-1.41-4.59 4.58z" fill="#747676"/></svg>
            </div>
            <ul v-show="open" class="input__options" ref="dropdownMenu" v-if="enabled">
                <li class="input__option" v-if="empty"
                    @mousedown="selectEmpty($event)" v-html="empty">
                </li>
                <li class="input__option" v-for="(option, index) in filteredOptions" :key="index"
                    :ref="index == 0 ? 'firstOption' : null"
                    @mousedown="select($event, option)" v-html="option.label + '&nbsp;' ">
                </li>
                <li class="input__option"  v-if="groupsAdditionalOption">
                    <hr>
                </li>
            </ul>
        </div>
        <span class="input__hint" v-html="hint" v-if="hint"></span>
    </div>
</template>
<script>
    import _ from 'lodash'

    export default {
        data() {
            return {
                localValues: [],
                localLabels: [],
                search: '',
                open: false,
                finalOptions: [],
                uuid: ''
            }
        },
        computed: {
            enabled() {
                return true
            },
            visibleLabel() {
                let selected = _.find(this.finalOptions, {value: this.value});
                if (selected)
                    if (this.prepend)
                        return this.prepend + ' ' + selected.label;
                    else
                        return selected.label;
                else if (!selected && this.empty)
                    return this.empty;
                else
                    return this.placeholder
            },
            filteredOptions() {
                const f = this.search.toLowerCase();
                if (f)
                    return _.filter(this.finalOptions, function (el) {
                        return el.label.toLowerCase().indexOf(f) > -1
                    });
                else
                    return this.finalOptions
            }
        },
        watch: {
            options(){
                this.finalOptions = this.options;
            }
        },
        mounted() {
            this.uuid = crypto.randomUUID()
            this.finalOptions = this.options;

            this.id = 'input-' + this.uuid;
            if (this.autoOpen)
                this.$refs.search.focus();

        },
        created() {
            _.each(this.finalOptions, (opt) => {
                this.localLabels.push(opt.label);
                this.localValues.push(opt.value)
            });
        },
        props: ['label', 'hint', 'value', 'options', 'searcheable', 'w', 'small', 'autoOpen', 'placeholder', 'prepend', 'empty', 'loading', 'searchInputMode', 'disabled', 'groupsAdditionalOption', 'onlySelect'],
        methods: {
            select(event, option) {
                this.open = false;
                this.$emit('input', option.value)
            },
            selectEmpty(event) {
                console.log(event)
                this.open = false;
                this.$emit('input', null)
            },
            onSearchBlur() {
                this.$refs.dropdownMenu.scrollTop = this.$refs.firstOption[0].offsetTop
                this.search = '';
                this.open = false
            },
            onSearchFocus() {
                if (this.enabled)
                    this.open = true

            },
        }

    }
</script>
<style lang="sass" scoped>

    .input
        flex: auto
        display: flex
        flex-direction: column
        /*margin-bottom: 25px*/
        text-align: left

        &__label
            font-size: 16px
            font-weight: normal
            display: flex
            align-items: center
            cursor: pointer

            .disabled &
                opacity: 0.5
                cursor: default

        &__hint
            padding-top: 5px

        &__box
            position: relative

        &__box_open

        &__selected
            /*flex-grow: 1*/
            /*flex-shrink: 0*/
            flex: auto
            white-space: nowrap
            overflow-x: hidden
            text-overflow: ellipsis
            margin-right: 5px
            padding-top: 1px

            // @media (max-width: 600px)
            //     text-align: center

        &__input
            display: flex
            flex: auto
            align-items: center
            line-height: 40px
            padding: 0 10px
            
            border: 1px solid #CED0D0
            border-radius: 8px
            font-size: 16px
            height: 48px
            position: relative
            cursor: pointer

            .disabled &
                color: #8CA0B3
                border-color: #C9D6E4
                cursor: default


            .input_small &
                height: 28px
                line-height: 28px
                font-size: 12px
                padding: 0px 8px
                border-radius: 3px

            .input__box_open &
                border-color: #004E98

            .mobile-dropdown &
                @media (max-width: 900px)
                    border: 1px solid #52BDC4
                    height: 48px
                    font-family: "Roboto"
                    font-weight: 700
                    font-size: 16px
                    line-height: 24px
                    color: #52BDC4

                    path 
                        fill: #52BDC4 !important

        &__options
            position: absolute
            left: 0
            width: 100%
            top: calc(100% + 1px)
            right: 100%
            list-style: none
            background-color: white
            z-index: 100
            margin: 0
            padding: 0
            border: 0px solid white
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1)
            border-radius: 8px
            max-height: 40px * 5 + 2px
            overflow-y: auto
            overflow-x: hidden

            .input_small &
                max-height: 24px * 15 + 2px

        &__option
            line-height: 40px
            padding: 0 10px
            margin: 0
            width: 100%
            font-size: 16px
            cursor: pointer
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden


            .input_small &
                font-size: 12px
                line-height: 24px
                padding: 0 5px

            &:hover
                background-color: #337AB7
                color: white

            hr
                margin: 0 -5px 0 -5px
                border-top: 1px solid black

        &__search
            border: 0
            padding: 0
            background: transparent
            /*flex: auto*/
            cursor: pointer
            width: 0
            /*display: none*/

            &::placeholder
                font-size: 16px
                font-family: "Avenir"


            &[type="number"]::-webkit-outer-spin-button,
            &[type="number"]::-webkit-inner-spin-button
                -webkit-appearance: none
                margin: 0

            &[type="number"]
                -moz-appearance: textfield


            .input__box_open &
                flex: auto
            /*display: initial*/
            /*width: auto*/



            &:focus
                cursor: text
                outline: none

        &__search--readonly
            &:focus
                cursor: pointer

</style>
