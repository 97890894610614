<template>
  <a :href="`/cars/` + car.slug" class="car-card" @click.prevent="doDataLayer">
    <div v-if="car.photos && car.photos.length" :style="{ 'backgroundImage': ('url(' + car.photos[0].url + ')') }" class="car-card-image"></div>
    <div v-else class="car-card-image">
      <PlaceholderImage />
    </div>

    <div class="car-card-status-line">
      <div v-if="car.vehicle_state == 'reserved'" class="car-card-status-line--reserved">{{ vehicleStateLabels.reserved }}</div>
      <div v-if="car.vehicle_state == 'unavailable'" class="car-card-status-line--enquiry">{{ vehicleStateLabels.unavailable }}</div>
      <div v-if="car.vehicle_state == 'archived'" class="car-card-status-line--archived">{{ vehicleStateLabels.archived }}</div>
      <div v-if="car.vehicle_state == 'available'" class="car-card-status-line--available">{{ vehicleStateLabels.available }}</div>
      <div v-if="car.vehicle_state == 'subscribed'" class="car-card-status-line--on-subscription">{{ vehicleStateLabels.subscribed }}</div>
      <div v-if="car.fuel_type.includes('electric')" class="car-card-status-line--electric"><ElectricIcon /></div>
    </div>

    <div class="car-card-make">{{car.year}} {{car.make}} {{car.model}} {{car.badge}}</div>
    <div class="car-card-type">{{car.body_style}}</div>
    <div class="car-card-pointers-line">
      <div class="car-card-pointers-line--item">{{car.transmission}}</div>
      <div class="car-card-pointers-line--item">{{car.fuel_type}}</div>
    </div>
    <div class="car-card-location"><LocationIcon /> {{location_options[car.account_id]}}</div>
    <template v-if="car.vehicle_state != 'subscribed' && car.vehicle_state != 'reserved'">
      <div class="car-card-from">from</div>
      <div class="car-card-price">${{car.subscription_price + min_plan_price}}<span class="smaller">/{{billing_frequency}}</span></div>
    </template>
  </a>
</template>

<script>
import LocationIcon from './icons/LocationIcon.vue';
import ElectricIcon from './icons/ElectricIcon.vue';
import PlaceholderImage from './images/PlaceholderImage.vue';

export default {
  name: "BigCarBox",
  components: {
    LocationIcon,
    ElectricIcon,
    PlaceholderImage,
  },
  props: {
    car: Object,
    location_options: Object,
  },
  data() {
    return {
    }
  },
  computed: {
    vehicleStateLabels() {
      return this.$store.state.vehicleStateLabels;
    },
  },
  methods: {
    doDataLayer() {
      var dataLayer = window.dataLayer || [];
      dataLayer.push({ 'event': 'selected_car', 'model': `${this.car.year} ${this.car.make} ${this.car.model} ${this.car.badge}`, 'selected_car_location': `${this.location_options[this.car.account_id]}` });
      window.location = `/cars/` + this.car.slug;
    }
  }
}
</script>
<style lang="scss" scoped>

.car-card {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 24px;

  width: 288px;
  min-height: 446px;
  text-decoration: none;
  background: #FFFFFF;
  border: 1px solid #F0F2F2;
  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  flex: none;
  flex-grow: 0;
  margin: 0px 12px 24px;
  text-align: left;
  
  box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 12px 0px, rgba(202, 202, 202, 0.26) 0px 0px 6px 0px, rgba(202, 202, 202, 0.16) 0px -1px 4px 0px;

  font-family: 'Roboto', sans-serif;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;

  .loading & {
    opacity: 0.5;
  }

  &:hover {
    border-color: #E1E3E4;
  }

  @media (max-width: 450px) {   
    width: 100%;
  }
}
.car-card * {
  -webkit-box-sizing: unset !important;
  -moz-box-sizing: unset !important;
  box-sizing: unset !important;
}
.car-card-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  flex-shrink: 0;
  background-position: center;

  svg {
    width: 100%;
    height: 100%;
  }
}
.car-card-status-line {
  padding: 16px 20px;
  height: 24px;
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #FFFFFF;

  &--enquiry {
    display: inline-block;
    height: 24px;
    background: #52BDC4;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
  &--archived {
    display: inline-block;
    height: 24px;
    background: #747676;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
  &--on-subscription {
    display: inline-block;
    height: 24px;
    background: #DFB80D;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
  &--available {
    display: inline-block;
    height: 24px;
    background: #2ED27A;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
  &--reserved {
    display: inline-block;
    height: 24px;
    background: #CACD38;
    border-radius: 8px;
    padding: 0px 8px;
    margin: 0px 4px 0 0;
    font-style: normal;
  }
}
.car-card-status-line--electric {
  display: inline-block;
  height: 24px;
  background: #52BDC4;
  border-radius: 8px;
  padding: 0 7px 0;
  margin: 0px 4px 0 0;
  font-style: normal;
  box-sizing: border-box !important;

  svg {
    margin: 0 0;
    position: relative;
    top: 4px;
  }
}
.car-card-make {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 0 22px 0 24px;
  /* Black */
  color: #1A1B1B;
}
.car-card-type {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0 22px 0 24px;
  color: #747676;
  text-transform: uppercase;
}
.car-card-pointers-line {
  padding: 8px 24px 12px;
  min-height: 24px;
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #747676;
}
.car-card-pointers-line--item {
  display: inline-block;
  background: rgba(241, 196, 0, 0.1);
  border-radius: 8px;
  padding: 0px 8px;
  margin: 4px 4px 0 0;
  font-style: normal;
}
.car-card-location {
  padding: 0 22px 0 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  flex-shrink: 0;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin: auto 0 16px 0;

  /* Blue (links) */
  color: #004E98;

  svg {
    margin-right: 4px;
  }
}
.car-card-from {
  padding: 0 22px 0 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1A1B1B;
  margin: 0 0 0 0;
}
.car-card-price {
  padding: 0 22px 0 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #1A1B1B;

  .smaller {
    font-size: 16px;
    line-height: 30px;
    margin-top: 10px;
  }
}
</style>