<template>
  <div class="small-car-box">
    <div class="small-car-box--info">
      <div v-if="car.photos && car.photos.length" :style="{ 'backgroundImage': ('url(' + car.photos[0].url + ')') }" class="small-car-box--info-image"></div>
      <div v-else class="small-car-box--info-image">
        <PlaceholderImage />
      </div>

      <div class="car-card-make">{{car.year}} {{car.make}} {{car.model}} {{car.badge}}</div>
      <div class="car-card-type">{{car.body_style}}</div>
      <div class="car-card-pointers-line">
        <div class="car-card-pointers-line--item">{{car.transmission}}</div>
        <div class="car-card-pointers-line--item">{{car.fuel_type}}</div>
      </div>
      <div class="car-card-location"><LocationIcon /> {{location_options[car.account_id]}}</div>
    </div>

    <div class="small-car-box--addons">
      <table>
        <tbody>
          <tr>
            <td>Car subscription</td>
            <td>${{chosen_plan.subscription_price}}</td>
          </tr>
          <tr v-if="chosen_plan.price">
            <td>{{chosen_plan.name}} plan</td>
            <td>${{chosen_plan.price}}</td>
          </tr>
          <tr v-if="km_package">
            <td>Add {{km_package.distance_allowance}} km</td>
            <td>${{parseInt(km_package.price)}}</td>
          </tr>
          <tr v-if="excess_package == 1">
            <td>Excess reduction</td>
            <td>
              ${{excess_adjustments.liability_protection_weekly}}
            </td>
          </tr>
          <tr class="result-row">
            <td>Total {{chosen_plan.billing_frequency}}</td>
            <td>${{resultPrice}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LocationIcon from './icons/LocationIcon.vue';
import PlaceholderImage from './images/PlaceholderImage.vue';

export default {
  name: "SmallCarBox",
  components: {
    LocationIcon,
    PlaceholderImage,
  },
  props: {
    car: Object,
    location_options: Object,
    chosen_plan: Object,
    km_package: Object,
    excess_package: Number,
    excess_adjustments: Object,
  },
  data() {
    return {
    }
  },
  computed: {
    resultPrice() {
      let price = this.chosen_plan.price;

      if (this.chosen_plan.subscription_price) {
        price += this.chosen_plan.subscription_price;
      }
      if (this.km_package) {
        price += parseInt(this.km_package.price);
      }
      if (this.excess_package == 1) {
        if (this.car.make === 'Polestar') {
          price += 100;
        } else {
          price += 45;
        }
      }

      return price;
    }
  }
}
</script>
<style lang="scss" scoped>
.small-car-box {
  flex-shrink: 0;
}
.small-car-box--info {
  width: 288px;
  background: #FFFFFF;
  border: 1px solid #CED0D0;
  border-radius: 16px;
  overflow: hidden;
  text-align: left;

  @media (max-width: 750px) {
    width: 100%;
  }

  &-image {
    width: 100%;
    height: 200px;
    background-size: cover;
    flex-shrink: 0;
    background-position: center;
    margin-bottom: 8px;

    @media (max-width: 750px) {
      height: calc( (100vw - 30px) / 100 * 69 );
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.car-card-make {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  padding: 0 22px 0 24px;
  /* Black */
  color: #1A1B1B;
}
.car-card-type {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0 22px 0 24px;
  color: #747676;
  text-transform: uppercase;
}
.car-card-pointers-line {
  padding: 8px 24px 12px;
  min-height: 24px;
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #747676;
}
.car-card-pointers-line--item {
  display: inline-block;
  background: rgba(241, 196, 0, 0.1);
  border-radius: 8px;
  padding: 0px 8px;
  margin: 4px 4px 0 0;
  font-style: normal;
}
.car-card-location {
  padding: 0 22px 0 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  flex-shrink: 0;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin: auto 0 24px 0;

  /* Blue (links) */
  color: #004E98;

  svg {
    margin-right: 4px;
  }
}


.small-car-box--addons {
  margin-top: 12px;
  padding: 30px 24px;
  background: #FEFAE6;
  border: 1px solid #CED0D0;
  border-radius: 16px;
}

.small-car-box--addons table {
  border-collapse: collapse;
  width: 100%;
}
.small-car-box--addons tr td {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1A1B1B;
  text-align: left;
  padding-bottom: 16px;
}
.small-car-box--addons table td:nth-child(2) {
  text-align: right;
}
tr.result-row {
  border-top: 1px solid #CED0D0;

  td {
    padding-top: 16px;
    padding-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1A1B1B;
  }
  td:nth-child(2) {
    font-size: 32px;
    line-height: 40px;
  }
}
</style>