<template>
  <div 
    class="relative"
    v-click-outside="handleFocusOut"
  >
    <input
      @input="(event) => update(event)"
      type="checkbox"
      :checked="checked"
      :id="fieldId"
      :value="fieldId"
      class="hidden"
    />
    <label
      :for="fieldId"
      class="checkbox-line cursor-pointer"
      :class="{ 'hidden': !visible }"
    >
      <CheckboxCheckedIcon v-if="checked" />
      <CheckboxUncheckedIcon v-else-if="!checked" />
      {{ label }}
    </label>
    <transition name="component-fade" appear>
      <SearchCommit v-if="clicked" :filter="contents" />
    </transition>
  </div>
</template>
<script>
import SearchCommit from "./SearchCommit.vue";
import CheckboxCheckedIcon from "./../icons/CheckboxCheckedIcon.vue";
import CheckboxUncheckedIcon from "./../icons/CheckboxUncheckedIcon.vue";

export default {
  props: {
    label: [Number, String],
    fieldName: [Number, String],
    fieldId: {
      type: [Number, String],
      required: true,
    },
    visible: {
      type: Boolean
    },
    contents: {
      type: Array,
    },
  },
  components: {
    SearchCommit,
    CheckboxCheckedIcon,
    CheckboxUncheckedIcon,
  },
  data() {
    return {
      checked: false,
      clicked: false,
    };
  },
  mounted() {
    // don't forget to "stringify" it
    let values = this.contents + '' || [];
    this.checked = values.includes(this.fieldId);

    this.emitter.on("commitSearch", () => {
      this.clicked = false;
    });
    this.emitter.on("removeTag", (tag) => {
      let values = this.contents || [];
      if (this.label == tag) {
        values = values.filter(function(value){ 
          return value != tag;
        });
        this.checked = false;
        this.$emit('update:contents', values);
      } else if (tag == '*') {
        this.checked = false;
        this.$emit('update:contents', []);
      } else if (this.fieldName == "location") {
        if (this.fieldId == tag)
          this.checked = false;
        values = values.filter(function(value){ 
          return value != tag;
        });
        this.$emit('update:contents', values);
      }
    });
  },
  methods: {
    update(event) {
      let values = this.contents || [];

      if (values.includes(event.target.value)) {
        this.checked = false;
        this.clicked = true;
        values = values.filter(function(value){ 
          return value != event.target.value;
        });
      } else {
        this.checked = true;
        this.clicked = true;
        values.push(event.target.value)
      }
      this.$emit('update:contents', values);
      if (this.clicked == true)
        this.emitter.emit("commitSearch", 'update');
        //this.emitter.emit("getCount", 'update');
    },
    handleFocusOut() {
      this.clicked = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.relative {
  position: relative;
  display: flex;
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}
.hidden {
  pointer-events: none;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  margin: 0;
}
.checkbox-line {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  padding: 0;
  align-items: center;
  vertical-align: center;
  display: inline-flex;
  margin: 5px 0;
  text-transform: capitalize;
  font-weight: normal;

  img {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 12px;
  }
  svg {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 12px;
    transition: background 0.1s;
  }
  &:hover svg{
    background: #F9F9F9;
  }
}
</style>