<template>
  <div 
    class="relative"
    v-click-outside="handleFocusOut"
  >
    <div class="wide">
      <Slider v-model="value"
        :lazy="false"
        :tooltips="false"
        :min="actualMin"
        :max="actualMax"
        @change="handleChange"
        @update="handleUpdate"
        style="width:100%"
      />
      <div class="inputs">
        <input type="text" v-model="displayMin" tabindex="-1" @keyup.enter="handleMin">
        <input type="text" v-model="displayMax" tabindex="-1" @keyup.enter="handleMax">
      </div>
    </div>
  
    <transition name="component-fade" appear>
      <SearchCommit v-if="clicked" :filter="contents" />
    </transition>
  </div>
</template>
<script>
import SearchCommit from "./SearchCommit.vue";
import Slider from '@vueform/slider'

export default {
  props: {
    name: String,
    min: Number,
    max: Number,
    round: Boolean,
    visible: {
      type: Boolean
    },
    initial: {
      type: Array,
    },
    contents: {
      type: Array,
    },
  },
  components: {
    SearchCommit,
    Slider,
  },
  data() {
    return {
      checked: false,
      clicked: false,
      value: [0, 0],
      actualMin: 0,
      actualMax: 9999999,
      displayMin: 0,
      displayMax: 0,
    };
  },
  mounted() {
    // Rounding
    if(this.round) {
      this.actualMin = Math.floor(this.min / 100) * 100;
      this.actualMax = Math.ceil(this.max / 100) * 100;
      this.value = [this.actualMin, this.actualMax];
    } else {
      this.actualMin = this.min;
      this.actualMax = this.max;
      this.value = [this.min, this.max];
    }

    // This restores display values from storage on page load...
    let storedFilter = JSON.parse(localStorage.getItem('filter'));
    if (this.name == "year") {
      if (storedFilter.year && storedFilter.year.length == 2) {
        this.value = [storedFilter.year[0], storedFilter.year[1]];
      }
    }
    if (this.name == "odometer") {
      if (storedFilter.odometer && storedFilter.odometer.length == 2) {
        this.value = [storedFilter.odometer[0], storedFilter.odometer[1]];
      }
    }

    this.emitter.on("resetRange", (name) => { 
      if (this.name === name)
        this.value = [this.actualMin, this.actualMax];
    });

    this.emitter.on("commitSearch", () => {
      this.clicked = false;
    });
  },
  methods: {
    handleChange(result) {
      this.displayMin = result[0];
      this.displayMax = result[1];
      this.$emit('update:contents', [result[0], result[1]]);

      this.clicked = true;
      this.emitter.emit("commitSearch", 'handleChange');
      //this.emitter.emit("getCount", 'handleChange');
    },
    handleUpdate(result) {
      this.displayMin = result[0];
      this.displayMax = result[1];
    },
    handleFocusOut() {
      this.clicked = false;
    },
    handleMin() {
      this.value = [this.displayMin, this.displayMax];
      this.$emit('update:contents', this.value);
      this.clicked = true;
      this.emitter.emit("commitSearch", 'handleMin');
      //this.emitter.emit("getCount", 'handleMin');
    },
    handleMax() {
      this.value = [this.displayMin, this.displayMax];
      this.$emit('update:contents', this.value);
      this.clicked = true;
      this.emitter.emit("commitSearch", 'handleMax');
      //this.emitter.emit("getCount", 'handleMax');
    }
  }
};
</script>
<style lang="scss" scoped>

.relative {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  --slider-bg: #E3E4E4;
  --slider-connect-bg: #52BDC4;
  --slider-height: 8px;
  --slider-handle-height: 20px;
  --slider-handle-width: 20px;
}
.wide {
  width: 100%;
  flex-shrink: 0;
  padding: 10px 24px 0 0;

  @media (max-width: 900px) {  
    padding: 10px 0 0 0;
  }
}
.inputs {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}
.inputs input {
  padding: 6px 12px;
  width: 92px;
  height: 36px;
  border: 1px solid #CED0D0;
  box-sizing: border-box;
  border-radius: 8px;
}
.cursor-pointer {
  cursor: pointer;
}
.hidden {
  pointer-events: none;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  margin: 0;
}
.checkbox-line {
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  padding: 0;
  align-items: center;
  vertical-align: center;
  display: inline-flex;
  margin: 5px 0;

  img {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 12px;
  }

  svg {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 12px;
  }
}
</style>
<style src="@vueform/slider/themes/default.css"></style>