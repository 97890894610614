<template>
  <div class="car-card-grid-package-inclusions">
    <div class="car-card-grid-package-inclusions--item" :class="{ open: item0 }">
      <svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="m18.0003 32.34-8.33999-8.34-2.84 2.82 11.17999 11.18 24-24-2.82-2.82z" fill="#dfb80d"/></svg>
      <div class="mobile-middle">
        <b @click.prevent="item0 = !item0">No lock-in contract</b>
        <span>Enjoy the freedom and flexibility of no long-term lock-in contracts for the lifetime of your car subscription. Subscribe to your vehicle for as long as you think you need, and if your circumstances change you can easily extend your subscription for 1 month, or 6! Alternatively, you can cancel anytime.</span>
      </div>
      <svg class="mobile-dropdown" @click.prevent="item0 = !item0" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" data-v-6ed41675=""><path d="m7.41 8.59-1.41 1.41 6 6 6-6-1.41-1.41-4.59 4.58z" fill="#000" data-v-6ed41675=""></path></svg>
    </div>
    <div class="car-card-grid-package-inclusions--item" :class="{ open: item1 }">
      <svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="m18.0003 32.34-8.33999-8.34-2.84 2.82 11.17999 11.18 24-24-2.82-2.82z" fill="#dfb80d"/></svg>
      <div class="mobile-middle">
        <b @click.prevent="item1 = !item1">Servicing & Maintenance</b>
        <span>You’ll never have to worry about servicing and maintenance of your vehicle while on subscription – we’ve got it covered! Looking to test drive a few different vehicles? Perfect! We’ll see you every 90 days for a service and a complimentary trade – allowing you to trial a new car every 3 months.</span>
      </div>
      <svg class="mobile-dropdown" @click.prevent="item1 = !item1" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" data-v-6ed41675=""><path d="m7.41 8.59-1.41 1.41 6 6 6-6-1.41-1.41-4.59 4.58z" fill="#000" data-v-6ed41675=""></path></svg>
    </div>
    <div class="car-card-grid-package-inclusions--item" :class="{ open: item2 }">
      <svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="m18.0003 32.34-8.33999-8.34-2.84 2.82 11.17999 11.18 24-24-2.82-2.82z" fill="#dfb80d"/></svg>
      <div class="mobile-middle">
        <b @click.prevent="item2 = !item2">Registration & Insurance</b>
        <span>The cost of insuring and registering a vehicle can be an expensive and tiring task, so let us take care of the hard work! Simply subscribe, and drive – let us handle the rest!</span>
      </div>
      <svg class="mobile-dropdown" @click.prevent="item2 = !item2" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" data-v-6ed41675=""><path d="m7.41 8.59-1.41 1.41 6 6 6-6-1.41-1.41-4.59 4.58z" fill="#000" data-v-6ed41675=""></path></svg>
    </div>
    <div class="car-card-grid-package-inclusions--item" :class="{ open: item3 }">
      <svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="m18.0003 32.34-8.33999-8.34-2.84 2.82 11.17999 11.18 24-24-2.82-2.82z" fill="#dfb80d"/></svg>
      <div class="mobile-middle">
        <b @click.prevent="item3 = !item3">Roadside assistance & Warranty</b>
        <span>Broken down, locked your keys in the car, or got a flat battery and need help? Too easy! Your eCar Subscription has you covered for any little inconveniences that may happen while on the road.</span>
      </div>
      <svg class="mobile-dropdown" @click.prevent="item3 = !item3" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" data-v-6ed41675=""><path d="m7.41 8.59-1.41 1.41 6 6 6-6-1.41-1.41-4.59 4.58z" fill="#000" data-v-6ed41675=""></path></svg>
    </div>
    <div class="car-card-grid-package-inclusions--item" :class="{ open: item4 }">
      <svg fill="none" height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="m18.0003 32.34-8.33999-8.34-2.84 2.82 11.17999 11.18 24-24-2.82-2.82z" fill="#dfb80d"/></svg>
      <div class="mobile-middle">
        <b @click.prevent="item4 = !item4">One fixed payment</b>
        <span>eCar Subscription provides the flexibility to set up payments on a weekly, fortnightly or monthly basis. With a variety of payment options and the ability for you to decide how often you set up payments, eCar Subscription is built to suit your lifestyle.</span>
      </div>
      <svg class="mobile-dropdown" @click.prevent="item4 = !item4" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" data-v-6ed41675=""><path d="m7.41 8.59-1.41 1.41 6 6 6-6-1.41-1.41-4.59 4.58z" fill="#000" data-v-6ed41675=""></path></svg>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      item0: false,
      item1: false,
      item2: false,
      item3: false,
      item4: false,
    }
    
  },
}
</script>

<style lang="scss" scoped>

.car-card-grid-package-inclusions {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    ". ."
    ". ."
    ". ."; 
  text-align: left;

  &--item {
    display: flex;
    margin-bottom: 40px;

    svg {
      flex-shrink: 0;
      margin-right: 24px;
    }

    .mobile-middle {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1A1B1B;

      b {
        display: block;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.005em;

        @media (max-width: 600px) {  
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
    .mobile-dropdown {
      display: none;
    }
  }


  @media (max-width: 900px) {  
    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.1));
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 0 20px;
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "."
      "."
      "."; 


    &--item {
      padding: 14px 14px;
      margin-bottom: 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-of-type {
        border-bottom: 0;
      }

      svg {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }

      .mobile-middle {
        width: 70%;
        b {
          margin-top: 3px;
          font-size: 18px;
        }
        span {
          display: block;
          max-height: 0;
          overflow: hidden;
          transition: all 0.25s ease-in-out;
        }
      }
      .mobile-dropdown {
        display: block;
        margin-left: auto;
        margin-right: 0;
        margin-top: 3px;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
      }

      &.open .mobile-middle span {
        max-height: 800px;
      }
      &.open .mobile-dropdown {
        transform: rotateX(180deg);
      }

    }
  }
}
</style>